/* For use in src/lib/core/theming/_palette.scss */
$palette-primary: (
  50 : #e4e9f2,
  100 : #bbc7df,
  200 : #8ea2ca,
  300 : #607cb4,
  400 : #3e60a4,
  500 : #1c4494,
  600 : #193e8c,
  700 : #143581,
  800 : #112d77,
  900 : #091f65,
  A100 : #97aaff,
  A200 : #6481ff,
  A400 : #3157ff,
  A700 : #1842ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$palette-accent: (
  50 : #fbfbfc,
  100 : #f5f5f7,
  200 : #eeeef2,
  300 : #e7e7ec,
  400 : #e1e1e8,
  500 : #dcdce4,
  600 : #d8d8e1,
  700 : #d3d3dd,
  800 : #ceced9,
  900 : #c5c5d1,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$palette-warn: (
  50 : #f6e0e4,
  100 : #e7b3bc,
  200 : #d88090,
  300 : #c84d63,
  400 : #bc2641,
  500 : #b00020,
  600 : #a9001c,
  700 : #a00018,
  800 : #970013,
  900 : #87000b,
  A100 : #ffb3b6,
  A200 : #ff8085,
  A400 : #ff4d53,
  A700 : #ff343b,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

