@use '@angular/material' as mat;
@import "palette";

@include mat.core();

$euroports-primary: mat.m2-define-palette($palette-primary);
$euroports-warn: mat.m2-define-palette($palette-warn);
$euroports-accent: mat.m2-define-palette($palette-accent);

$euroports-theme: mat.m2-define-light-theme((
  color: (
    primary: $euroports-primary,
    warn: $euroports-warn,
    accent: $euroports-accent,
  ),
  typography: mat.m2-define-typography-config()
));

@include mat.all-component-themes($euroports-theme);

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--color-accent);
}

html {
  --color-primary: #{mat.m2-get-color-from-palette($euroports-primary)};
  --color-accent: #{mat.m2-get-color-from-palette($euroports-accent)};
  --color-warn: #{mat.m2-get-color-from-palette($euroports-warn)};
}

.form {
  display: flex;
  flex-flow: column wrap;
}

.d-flex {
  display: flex;
}

.align-baseline {
  align-items: baseline;
}

.gap-20 {
  gap: 20px;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.row-no-gap {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.form-group-color {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid mat.m2-get-color-from-palette($euroports-primary);
}

.padding-10 {
  padding: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.full-width {
  flex-grow: 1;
}

.align-right {
  margin-left: auto;
}

input.no-spin-button::-webkit-outer-spin-button,
input.no-spin-button::-webkit-inner-spin-button {
  display: none;
}
